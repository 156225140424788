<template>
  <el-container class="h-full">
    <page-header
      class="absolute"
      style="padding: 20px 30px; width: calc(100% - 335px)"
      :title="__('XSI')"
      :navItems="navItems"
      @navItemChanged="handleNavItemChanged"
    ></page-header>
    <component v-bind:is="component"></component>
  </el-container>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import _ from "lodash";

export default {
  name: "XSIManageIndex",
  components: {
    PageHeader
  },

  data() {
    return {
      selectedComponent: "xsi-profiles",
      selectedIndex: null,
      navItems: [
        {
          label: __("XSI Profiles"),
          active: true,
          path: "xsi-profiles",
          icon: "icon-general-profile.svg"
        },
        {
          label: __("XSI Subscriptions"),
          active: false,
          path: "xsi-subscriptions",
          icon: "icon-xsi-subscription.svg"
        }
      ]
    };
  },

  computed: {
    component() {
      return this.importComponent(this.selectedComponent);
    }
  },

  methods: {
    importComponent(path) {
      return () => import("@/views/manage/xsi/" + path + "/index.vue");
    },

    handleNavItemChanged(navItem) {
      this.selectedComponent = navItem.path;
      this.navItems = _.map(this.navItems, navItem => {
        navItem.active = false;
        return navItem;
      });
      _.find(this.navItems, { label: navItem.label }).active = true;
    }
  }
};
</script>

<style lang="scss" scoped></style>
